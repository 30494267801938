import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Discipline } from '../../components/Discipline/Discipline';
import { useStores } from '../../custom-hooks/store/use-stores';
import { AssessmentService } from '../../services/dataServices/AssessmentService';
import { ReportService } from '../../services/dataServices/ReportService';
import { Logo } from '../../shared/logo';
import { Report, Test } from '../../types';
import './Disciplines.scss';

interface Assessment {
  name: string,
  description: string,
  assessmentType?: string,
  _id: string,
  taxonomies: string[],
  report: Report,
  type?: string,
  duration?: number
}

interface State {
  sendResults: boolean
}

const EDUCATION = 'education';
const SCREENING = 'screening';

export const DisciplinesPage = () => {
  const history = useHistory();
  const { state } = useLocation<State>();
  const [assessments, setAssessments] = useState<Test[]>([]);
  const [mandatoryTests, setMandatoryTests] = useState<Test[]>([]);
  const [unlockAllTests, setUnlockAllTests] = useState(false);
  const [allTestsArePassed, setAllTestArePassed] = useState(false);
  const [assessmentType, setAssessmentType] = useState<'education'| 'internship' | 'standard' | 'screening' | ''>('');

  const {
    userStore: {
      user: {
        assessment: assessmentId,
        _id: userId,
        email,
      },
    },
  } = useStores();

  const getAssessments = async () => {
    const assessment = await AssessmentService.getAssessment(assessmentId, { params: { candidate: userId } }); // eslint-disable-line
    const isOverdue = (new Date().getTime() - new Date(assessment?.data.endDate).getTime() > 0);
    const isNotStarted = (new Date().getTime() - new Date(assessment?.data.startDate).getTime() < 0);
    setAssessmentType(assessment.data.assessmentType);
    let invalidAssesment = null;
    if (isOverdue) {
      invalidAssesment = {
        status: 'finished',
        date: assessment?.data.endDate,
      };
    } else if (isNotStarted) {
      invalidAssesment = {
        status: 'notStarted',
        date: assessment?.data.startDate,
      };
    }

    if (isOverdue || (isNotStarted && !email.endsWith('@griddynamics.com'))) {
      history.replace('/404', {
        invalidAssesment,
      });
      return;
    }

    if (assessment.data.mandatoryTests) {
      setMandatoryTests(assessment.data.mandatoryTests);
    }
    setAssessments(assessment.data.tests);
  };

  useEffect(() => {
    getAssessments();
  }, []);

  const mandatoryTestsCompleted = (shouldBeCompleted: number, arrOfTests: Test[]) => {
    const actualCompleted = arrOfTests.filter((el) => el.report?.isCompleted).length;
    setUnlockAllTests(actualCompleted === shouldBeCompleted);
  };

  useEffect(() => {
    mandatoryTestsCompleted(mandatoryTests.length, mandatoryTests);
  }, [mandatoryTests]);

  const allTestsCompleted = (shouldBeCompleted: number, arrOfTests: Test[]) => {
    const actualCompleted = arrOfTests.filter((el) => el.report?.isCompleted).length;
    setAllTestArePassed(unlockAllTests && actualCompleted === shouldBeCompleted);
  };

  useEffect(() => {
    allTestsCompleted(assessments.length, assessments);
  }, [assessments]);

  const sendAssessmentResult = async () => {
    const result = {
      candidate: userId,
      assessment: assessmentId,
    };
    await AssessmentService.sendAssessmentResult(result);
  };

  useEffect(() => {
    if (allTestsArePassed && state?.sendResults) {
      sendAssessmentResult();
      history.replace('/disciplines');
    }
  }, [allTestsArePassed, state]);

  const handleTestStart = (id: string, isEducational: boolean, isScreening: boolean) => {
    let foundChosenTest;
    if (unlockAllTests) {
      foundChosenTest = assessments.find((elem: Test) => elem._id === id);
    } else {
      foundChosenTest = mandatoryTests.find((elem: Test) => elem._id === id);
    }

    if (!foundChosenTest) {
      return;
    }

    if (foundChosenTest.report && (!isEducational)) {
      history.push(`/test/${id}?report=${foundChosenTest.report._id}`);
      return;
    }

    // TODO: do we need summary when we continue?
    if (foundChosenTest.report && isEducational) {
      history.push(`/education-tasks/${id}`);
      return;
    }

    const data = {
      'assessment': assessmentId,
      'candidate': userId,
      'test': `${id}`,
      'answers': [],
    };

    if (isEducational) {
      history.push(`/education/${id}${foundChosenTest?.report?.isCompleted || '?isCompleted=true'}`);
      return;
    }

    if (isScreening) {
      history.push(`/screening/${id}${foundChosenTest?.report?.isCompleted || '?isCompleted=true'}`);
      return;
    }

    ReportService.createReport(data).then((res) => {
      history.push(`/test/${id}?report=${res.data._id}`);
    });
  };

  return (
    <div className="disciplines-page">
      <div className="disciplines-page__logo">
        <Logo />
      </div>
      <div className="disciplines-page__title">
        Welcome to the Grid Dynamics {assessmentType} assessment!
      </div>
      <div className="disciplines-page__subtitle">
        {assessmentType === 'internship'
          ? 'Here you’ll find a list of tests for internship opportunities offered in Grid Dynamics. Pass all the general knowledge tests to get access to specialization (such as UI, Big Data, Java, etc.) ones.'
          : 'Here you’ll find a list of tests. You should pass all the general knowledge tests to get access to specialization ones.'}

        <p>
          After completing specialization testing, you will qualify for an
          interview with company representatives. You’ll also see the test
          results for each test you’ve completed.
        </p>
        <strong>When taking the tests, follow these instructions:</strong>

        {assessmentType === EDUCATION ? (
          <ol>
            <li>
              Answer the questions within a given time: keep an eye on the timer.
            </li>
            <li>
              Try to answer all questions. You can go back to previous questions if needed.
            </li>
          </ol>
        ) : (
          <ol>
            <li>
              Answer a question within a given time: keep an eye on the timer.
            </li>
            <li>
              Try not to skip questions: you can’t return to previous questions.
            </li>
            <li>
              Stay on the test tab: changing or closing it will automatically mark
              the question as non-answered.
            </li>
          </ol>
        )}
      </div>

      {mandatoryTests.length > 0 ? (
        <div className="disciplines-page__items">
          <div className="disciplines-page__items-title" data-testid="items-title">
            General knowledge testing
          </div>
          <div className="disciplines-page__items-subtitle">
            {unlockAllTests
              ? ''
              : 'Complete the general knowledge test(s) in this section to unlock the specialization testing.'}
          </div>
          <div className="disciplines-page__item">
            {mandatoryTests.map(({ name, _id, report }: Assessment) => (
              <Discipline
                key={Math.random()}
                showResults={allTestsArePassed}
                name={name}
                id={_id}
                result={report?.result}
                report={report}
                popupHandler={() => handleTestStart(
                  _id,
                  assessmentType === EDUCATION,
                  assessmentType === SCREENING,
                )}
              />
            ))}
          </div>
        </div>
      ) : null}
      <div className="disciplines-page__items">
        {mandatoryTests.length > 0 ? (
          <div>
            <div className="disciplines-page__items-title" data-testid="items-title">
              Specialization testing
            </div>
            <div className="disciplines-page__items-subtitle">
              {unlockAllTests
                ? 'To qualify for a technical interview, complete one or more specialization tests'
                : '❗Complete the general knowledge testing to unlock these tests.'}
            </div>
          </div>
        ) : null}
        <div className="disciplines-page__item">
          {assessments.map(({ name, _id, report, type, duration }: Assessment) => (
            <Discipline
              key={Math.random()}
              showResults={allTestsArePassed}
              result={report?.result}
              name={name}
              isButtonDisabled={unlockAllTests}
              optionalTests
              id={_id}
              report={report}
              popupHandler={() => handleTestStart(
                _id,
                assessmentType === EDUCATION,
                assessmentType === SCREENING,
              )}
              type={type}
              duration={duration}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import {
  useHistory,
  useParams,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ResultPage } from '../ResultPage/ResultPage';
import { TestQuestion } from '../TestQuestionPage/TestQuestion';
import { Spinner } from '../../components/Spinner/Spinner';
import { useStores } from '../../custom-hooks/store/use-stores';
import { Answer, Question } from '../../types';
import { ReportService } from '../../services';
import { Button } from '../../components/Button/Button';
import { QuestionService } from '../../services/dataServices/QuestionService';
import { fillWithAnswers, fillEmptyCodeAnswers } from '../../components/helpers';
import { getTaskTime } from '../EducationTaskPage/getTaskTime';

type Params = {
  testId: string
  questionID: string;
};

type TestPageProps = {
  reportId: string,
  takenAnswers: Answer[],
  testQuestions: Question[],
  activeQuestion: number,
  setActiveQuestion: (val : number) => void,
  test: any
};

const initialTimer = 60000;

export const TestPage = ({ takenAnswers, test, activeQuestion, reportId, setActiveQuestion }: TestPageProps) => {
  const {
    userStore: {
      user: {
        _id: userId,
        assessment: assessmentId,
      },
    },
  } = useStores();

  const history = useHistory();
  const params = useParams<Params>();
  const [sendingReport, setSendingReport] = useState(false);
  const [userTypedAnswer, setUserTypedAnswer] = useState('');
  const [timerId, setTimerId] = useState<number | undefined>();
  const [runTimer, setRunTimer] = useState(false);
  const disciplineID: string = params.testId;
  const [timer, setTimer] = useState<number>(initialTimer);
  const [userChosenAnswer, setUserChosenAnswer] = useState<Answer[]>([]);
  const [chosenAnswer, setChosenAnswer] = useState<Answer>();
  const { path } = useRouteMatch();
  const match = useRouteMatch();
  const [snackbar, setSnackbar] = useState(false);
  const [currentQuestionInfo, setCurrentQuestionInfo] = useState<any>({});
  const openQuestion = 2;
  const tick = useRef<number>();

  const testStartedTimeQuery = `testStartedTime:${disciplineID}${userId}${assessmentId}`;
  const timerQuery = `timer:${disciplineID}${userId}${assessmentId}:${activeQuestion}`;

  useEffect(() => {
    if (runTimer) {
      tick.current = window.setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1000);
      }, 1000);
      setTimerId(tick.current);
    } else {
      clearInterval(tick.current);
      setTimerId(undefined);
    }

    return () => clearInterval(tick.current);
  }, [runTimer]);

  useEffect(() => {
    const questionID = takenAnswers[activeQuestion]?.questionId;
    if (!test) {
      return;
    }

    async function fetchData() {
      const question = await QuestionService.findOrGetQuestion(test?.questions, questionID);
      setCurrentQuestionInfo(question);
    }

    fetchData();
  }, [activeQuestion, takenAnswers, test]);

  const handleNext = () => {
    localStorage.removeItem(timerQuery);
    setRunTimer(false);
    const prevCameAnswersFilled: any = fillWithAnswers(takenAnswers, userChosenAnswer);
    const prevCameAnswers = fillEmptyCodeAnswers(prevCameAnswersFilled, activeQuestion);

    if (activeQuestion + 2 > takenAnswers.length) {
      const data = {
        'assessment': assessmentId,
        'candidate': userId,
        'test': disciplineID,
        'answers': prevCameAnswers,
        isCompleted: true,
        time: getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString()),
      };

      clearInterval(timerId!);
      setSendingReport(true);
      ReportService.updateReport(reportId, data).finally(() => {
        setSendingReport(false);
      });

      history.replace(`${match.url}/results`);
      localStorage.removeItem(timerQuery);
      localStorage.removeItem(testStartedTimeQuery);
      localStorage.removeItem(`maxQuestion:${disciplineID}${userId}${assessmentId}`);
      return;
    }

    const unFinishedData = {
      'assessment': assessmentId,
      'candidate': userId,
      'test': `${disciplineID}`,
      'answers': prevCameAnswers,
    };

    setSendingReport(true);
    ReportService.updateReport(reportId, unFinishedData).finally(() => {
      setSendingReport(false);
    });

    setActiveQuestion(activeQuestion + 1);
  };

  const handleSkipQuestion = () => {
    const prevCameAnswers: any = fillWithAnswers(takenAnswers, userChosenAnswer);
    const nextUserChoseAnswer = fillEmptyCodeAnswers(prevCameAnswers, activeQuestion);
    if (nextUserChoseAnswer[activeQuestion] && nextUserChoseAnswer[activeQuestion].answerIds) {
      nextUserChoseAnswer[activeQuestion].answerIds = [];
    }

    setUserChosenAnswer(nextUserChoseAnswer);

    handleNext();
  };

  useEffect(() => {
    const onBlur = () => {
      setSnackbar(true);
      handleSkipQuestion();
    };

    if (location.pathname !== `/test/${disciplineID}/results`) {
      window.addEventListener('blur', onBlur);
    }

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [disciplineID, activeQuestion]);

  useEffect(() => {
    const preventBack = () => {
      window.history.forward();
      window.onunload = null;
    };

    window.addEventListener('popstate', preventBack);
    window.addEventListener('pushstate', preventBack);
    return () => {
      window.removeEventListener('popstate', preventBack);
      window.removeEventListener('pushstate', preventBack);
    };
  }, [snackbar]);

  useEffect(() => {
    const allChosenAnswers: any = [...userChosenAnswer];
    allChosenAnswers[activeQuestion] = chosenAnswer;
    setUserChosenAnswer(allChosenAnswers);
  }, [activeQuestion, chosenAnswer]);

  useEffect(() => {
    if (!takenAnswers.length || !currentQuestionInfo?.time) {
      return;
    }
    if (!takenAnswers[activeQuestion]) {
      if (location.pathname === `/test/${disciplineID}/results`) {
        return;
      }
      const prevCameAnswersFilled: any = fillWithAnswers(takenAnswers, userChosenAnswer);
      const prevCameAnswers = fillEmptyCodeAnswers(prevCameAnswersFilled, activeQuestion);
      const data = {
        'assessment': assessmentId,
        'candidate': userId,
        'test': disciplineID,
        'answers': prevCameAnswers,
        isCompleted: true,
        time: getTaskTime(localStorage.getItem(testStartedTimeQuery), new Date().getTime().toString()),
      };

      setSendingReport(true);
      ReportService.updateReport(reportId, data).finally(() => {
        setSendingReport(false);
      });

      history.replace(`${match.url}/results`);
      localStorage.removeItem(`maxQuestion:${disciplineID}${userId}${assessmentId}`);
      localStorage.removeItem(testStartedTimeQuery);
      return;
    }

    const isCurrentTest = localStorage.getItem('test') === disciplineID;
    const timeReload: string | null = isCurrentTest ? localStorage.getItem(timerQuery) || '0' : null;
    const nextTimer = !timeReload || Number(timeReload) === 0 ? currentQuestionInfo?.time * 60000 : Number(timeReload);
    setTimer(nextTimer);

    if (!snackbar) {
      setRunTimer(true);
    }

    // return () => {
    //   clearInterval(timeoutId);
    //   localStorage.removeItem('timer');
    // };
  }, [activeQuestion, history, currentQuestionInfo, takenAnswers]);

  useEffect(() => {
    if (snackbar) setRunTimer(false);
  }, [snackbar]);

  useEffect(() => {
    if (timer - 1000 < 0) {
      localStorage.removeItem(timerQuery);
      clearInterval(timerId!);
      setRunTimer(false);
      setActiveQuestion(activeQuestion + 1);
      const prevCameAnswersFilled: any = fillWithAnswers(takenAnswers, userChosenAnswer);
      const prevCameAnswers = fillEmptyCodeAnswers(prevCameAnswersFilled, activeQuestion);
      const unFinishedData = {
        'assessment': assessmentId,
        'candidate': userId,
        'test': `${disciplineID}`,
        'answers': prevCameAnswers,
      };

      setSendingReport(true);
      ReportService.updateReport(reportId, unFinishedData).finally(() => {
        setSendingReport(false);
      });
    }
  }, [timer, timerId]);

  useEffect(() => {
    if (timerId) {
      localStorage.setItem(timerQuery, timer.toString());
      localStorage.setItem('test', disciplineID);
    }

    if (!localStorage.getItem(testStartedTimeQuery)) {
      localStorage.setItem(testStartedTimeQuery, new Date().getTime().toString());
    }
  }, [timerId, timer]);

  useEffect(() => {
    let allChosenAnswer: Answer;
    if (currentQuestionInfo?._id) {
      allChosenAnswer = {
        questionId: currentQuestionInfo._id,
        answerIds: [],
      };
      if (currentQuestionInfo?.questionType === openQuestion) {
        allChosenAnswer = {
          questionId: currentQuestionInfo._id,
          code: {
            message: '',
            languageId: currentQuestionInfo?.code?.languageId,
          },
        };
      }
      setChosenAnswer(allChosenAnswer);
    }
  }, [activeQuestion, currentQuestionInfo]);

  useEffect(() => {
    if (activeQuestion < takenAnswers.length && location.pathname !== `/test/${disciplineID}/results`) {
      history.replace(`${match.url}/question/${activeQuestion + 1}`);
    }
  }, [activeQuestion, history, takenAnswers]);

  if (sendingReport) {
    return <Spinner />;
  }

  const handleClose = () => {
    setSnackbar(false);
    setRunTimer(true);
  };

  return (
    <>
      <Switch>
        <Route
          path={`${path}/results`}
          render={() => {
            return <ResultPage />;
          }}
        />
        <Route
          path={`${path}/question/:questionId`}
          render={() => {
            return (
              <>
                <TestQuestion
                  activeQuestion={activeQuestion}
                  setChosenAnswer={setChosenAnswer}
                  handleNext={handleNext}
                  userTypedAnswer={userTypedAnswer}
                  setUserTypedAnswer={setUserTypedAnswer}
                  chosenAnswer={chosenAnswer}
                  timer={timer}
                  takenAnswers={takenAnswers}
                  testQuestions={currentQuestionInfo}
                  userChosenAnswer={userChosenAnswer}
                  allQuestionsLength={takenAnswers.length}
                />
                <Dialog
                  open={snackbar}
                  keepMounted
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle id="alert-dialog-slide-title">Question skipped due to changing focus</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Because you’ve changed the focus from test tab and switched to other application / browser tab, your question is marked as non-answered and a new one is loaded.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button title="I understand" onClick={handleClose} />
                  </DialogActions>
                </Dialog>
              </>
            );
          }}
        />
      </Switch>
    </>
  );
};

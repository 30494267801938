import React, { useState, useCallback, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { AssessmentService } from '../../services';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { CheckBox } from '../CheckBox/CheckBox';
import { Error } from '../Error/Error';
import {
  getEmailError,
  getNameError,
  getUniversityError,
  getAgreeError,
  getAllowContactError,
  getPhoneError,
  getFileError,
} from './helpers';
import './RegForm.scss';
import 'react-phone-input-2/lib/style.css';

const defaultFileName = 'undefined.pdf';

export interface Errors {
  email?: React.ReactChild;
  name?: React.ReactChild;
  agree?: React.ReactChild;
  allowContact?: React.ReactChild;
}

export interface Values {
  email: string;
  name: string;
  university: string,
  phone?: string;
  agree: boolean;
  file: File;
  allowContact: boolean;
}

type RegFormProps = {
  onSubmit: (values: any, isScreening: boolean) => void;
  disableBtn: boolean;
};

export const RegForm = ({ onSubmit, disableBtn }: RegFormProps) => {
  const [values, setValues] = useState<Values>({
    email: '',
    name: '',
    university: '',
    phone: '',
    agree: false,
    allowContact: false,
    file: new File(['undefined'], defaultFileName, { type: 'application/pdf' }),
  });

  const [phone, setPhone] = useState<any>({
    phoneWithNoCode: '',
    touched: false,
    phoneCountryCode: {
      countryCode: 'us',
      dialCode: '1',
      format: '+. (...) ...-....',
      name: 'United States',
    },
  });

  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [universityError, setUniversityError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [agreeError, setAgreeError] = useState('');
  const [fileError, setFileError] = useState('');
  const [allowContactError, setAllowContactError] = useState('');
  const [isCvRequired, setIsCvRequired] = useState(false);
  const [isInternship, setIsInternship] = useState(false);
  const [isScreening, setIsScreening] = useState(false);

  const getAssessmentType = async () => {
    const assessmentId = window.location.href.split('/')[
      window.location.href.split('/').length - 1
    ];
    const config = {
      headers: {
        bypass: 'true',
      },
    };

    const { data } = await AssessmentService.getAssessmentType(
      assessmentId,
      config,
    ) || {};
    setIsCvRequired(data === 'internship');
    setIsInternship(data === 'internship');
    setIsScreening(data === 'screening');
  };

  useEffect(() => {
    getAssessmentType();
  }, []);

  const validateEmail = (value: string): void => {
    setEmailError(getEmailError(value));
  };

  const validateName = (value: string): void => {
    setNameError(getNameError(value));
  };

  const validateUniversity = (value: string): void => {
    setUniversityError(getUniversityError(value));
  };

  const validateFile = (value: File): void => {
    setFileError(getFileError(value));
  };

  const validatePhone = (value: string, country: string): void => {
    setPhoneError(getPhoneError(value, country));
  };

  const validateAgreeCheckBox = (value: boolean): void => {
    setAgreeError(getAgreeError(value));
  };

  const validateAllowContactCheckBox = (value: boolean): void => {
    setAllowContactError(getAllowContactError(value));
  };

  const handleApply = async (): Promise<void> => {
    const emailErr = getEmailError(values.email);
    setEmailError(emailErr);

    const nameErr = getNameError(values.name.trim());
    setNameError(nameErr);

    const phoneErr = getPhoneError(
      phone.phoneWithNoCode,
      phone.phoneCountryCode,
    );
    setPhoneError(phoneErr);

    const agreeErr = getAgreeError(values.agree);
    setAgreeError(agreeErr);

    const allowContactErr = getAllowContactError(values.allowContact);
    setAllowContactError(allowContactErr);

    const fileErr = isCvRequired ? getFileError(values.file) : '';
    setFileError(isCvRequired ? fileErr : 'no file required');

    if (
      emailErr === ''
      && phoneErr === ''
      && nameErr === ''
      && agreeErr === ''
      && allowContactErr === ''
      && fileErr === ''
    ) {
      onSubmit({ ...values, phone: `+${values.phone}` }, isScreening);
    }
  };

  return (
    <form className="registration-form">
      <h1 className="registration-form__header" data-testid="header">
        Join {isInternship ? 'Internship' : ''} at Grid Dynamics
      </h1>
      <p className="registration-form__subheader">
        To apply{isInternship ? ' for the Internship program' : ''}, you need to take general knowledge
        and skills tests. Sign up to start your {isInternship ? 'internship journey' : 'journey with Grid Dynamics'}!
      </p>
      <div className="registration-form__inputs-wrapper">
        <Input
          className={`${emailError ? 'input__error' : ''}`}
          required
          onChange={useCallback(
            (e: any) => {
              setValues({ ...values, email: e.target.value });
              validateEmail(e.target.value);
            },
            [values, validateEmail],
          )}
          id="email"
          label="Email"
          name="email"
          value={values.email}
          type="email"
          placeholder="example@mail.com"
          htmlFor="email"
        />
        <Error data-testid="email-error">{emailError}</Error>

        <Input
          className={`${nameError ? 'input__error' : ''}`}
          required
          onChange={useCallback(
            (e: any) => {
              setValues({ ...values, name: e.target.value });
              validateName(e.target.value);
            },
            [values, validateName],
          )}
          id="fullname"
          value={values.name}
          label="Full name"
          name="fullname"
          type="text"
          placeholder="Andy Smith"
          htmlFor="fullname"
        />
        <Error data-testid="lastname-error">{nameError}</Error>
        {
          isCvRequired && (
            <div className="registration-form__inputs-wrapper--university">
              <Input
                className={`${universityError ? 'input__error' : ''}`}
                onChange={(e: any) => {
                  setValues({ ...values, university: e.target.value });
                  validateUniversity(e.target.value);
                }}
                id="university"
                value={values.university}
                label="University"
                name="university"
                type="text"
                placeholder="University of Oxford"
                htmlFor="university"
              />
              <Error data-testid="lastname-error">{universityError}</Error>
            </div>
          )
        }
        <div className="registration-form__inputs-wrapper--multiple">
          <div>
            <div className="asterics-wrapper">
              <PhoneInput
                country="us"
                data-testid="phone-input"
                value={values.phone}
                onBlur={() => setPhone({ ...phone, touched: true })}
                enableSearch
                onChange={useCallback(
                  (phoneVal, country) => {
                    setValues({ ...values, phone: phoneVal });
                    setPhone({
                      ...phone,
                      phoneCountryCode: country,
                      phoneWithNoCode: `+${phoneVal}`,
                    });
                    if (phone.touched) {
                      validatePhone(`+${phoneVal}`, country);
                    }
                  },
                  [values, validatePhone],
                )}
              />
              <div className="registration-form__asterics">*</div>
            </div>
            <Error data-testid="phone-error">{phoneError}</Error>
          </div>
          {isCvRequired && (
            <div className="registration-form__inputs-wrapper--multiple__section">
              <p>
                CV<span className="registration-form__asterics">*</span>
              </p>
              <div className="registration-form__inputs-wrapper--multiple__subsection">
                <label htmlFor="file-upload" className="file-upload">
                  {values.file && values.file.name !== defaultFileName
                    ? 'CV was attached'
                    : 'Attach CV'}
                </label>
                <input
                  type="file"
                  id="file-upload"
                  accept=".pdf"
                  onChange={(e: any) => {
                    if (e.target.files.length > 0) {
                      setValues({ ...values, file: e.target.files[0] });
                      validateFile(e.target.files[0]);
                    } else {
                      setValues({ ...values });
                    }
                  }}
                />
                <Error data-testid="file-error">{fileError}</Error>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="registration-form__agreeblock">
        <CheckBox
          data-testid="checkbox"
          checked={values.agree}
          onChange={useCallback(
            (checked: boolean) => {
              setValues({ ...values, agree: checked });
              validateAgreeCheckBox(checked);
            },
            [values, validateAgreeCheckBox],
          )}
        >
          <div className="registration-form__agree">
            <span className="registration-form__asterics">*</span>I have read
            and accepted the{' '}
            <a
              href="https://www.griddynamics.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="registration-form__privacy"
              data-testid="privacy-link"
            >
              {' '}
              Privacy Policy
            </a>
            <Error data-testid="checkbox-error">{agreeError}</Error>
          </div>
        </CheckBox>
        <CheckBox
          checked={values.allowContact}
          onChange={useCallback(
            (checked: boolean) => {
              setValues({ ...values, allowContact: checked });
              validateAllowContactCheckBox(checked);
            },
            [values, validateAllowContactCheckBox],
          )}
        >
          <div className="registration-form__agree">
            <span className="registration-form__asterics">*</span>I allow Grid
            Dynamics to contact me.
            <Error data-testid="checkbox-error">{allowContactError}</Error>
          </div>
        </CheckBox>
      </div>
      <div className="registration-form__apply-btn">
        <Button
          onClick={handleApply}
          title="Apply"
          disabled={
            (isCvRequired && values.file.name === defaultFileName) || disableBtn
          }
        />
      </div>
    </form>
  );
};

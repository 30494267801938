import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TestPage } from './TestPage';
import { TestService } from '../../services';
import { Button } from '../../components/Button/Button';
import { useStores } from '../../custom-hooks/store/use-stores';
import { Spinner } from '../../components/Spinner/Spinner';
import { useMaxActiveQuestion } from '../../hooks/useMaxActiveQuestion';

import './TestPage.scss';

export const Test = () => {
  const params = useParams<any>();
  const {
    userStore: {
      user: {
        _id: userId, assessment: assessmentId,
      },
    },
  } = useStores();

  const history = useHistory();
  const [test, setTest] = useState<any>();
  const disciplineID: string = params.testId;
  const [loading, setLoading] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const { maxActiveQuestion, getMaxActiveQuestion } = useMaxActiveQuestion(activeQuestion, userId, assessmentId);

  useEffect(() => {
    if (!test || !maxActiveQuestion) {
      return;
    }
    if (activeQuestion < test.report.answers.length) {
      localStorage.setItem(`maxQuestion:${test?._id}${userId}${assessmentId}`, maxActiveQuestion.toString());
    }
  }, [maxActiveQuestion, test?._id]);

  useEffect(() => {
    TestService.getTest(disciplineID, { params: { populate: true, candidate: userId } })
      .then((resp) => {
        setTest(resp.data);
        setActiveQuestion(getMaxActiveQuestion(resp.data._id));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (!test?.report) {
    return (
      <div className="testLoad">
        <div className="testLoad-title" data-testid="testLoad-title">Oops, failed to load the test...</div>
        <Button title="Try Again" onClick={() => history.replace('/disciplines')} />
      </div>
    );
  }

  return (
    <TestPage
      setActiveQuestion={setActiveQuestion}
      reportId={test.report._id}
      takenAnswers={test.report.answers}
      testQuestions={test.questions}
      activeQuestion={activeQuestion}
      test={test}
    />
  );
};
